// @flow

import { core } from '@accordo-feed/micro-frontends-utils';

import { API_KEYS } from 'src/constants';
import { getApiAddress, requestHandler } from 'src/utils';

const usersApi = getApiAddress(API_KEYS.USERS_API);

export const fetchUserList = (orgId: string) =>
  requestHandler({
    url: core.replaceAll(usersApi, { orgId })
  });
