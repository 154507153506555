import * as R from 'ramda';

const renameKeys = R.curry((keysMap, obj) => {
  return R.reduce(
    (acc, key) => {
      acc[keysMap[key] || key] = obj[key];
      return acc;
    },
    {},
    R.keys(obj)
  );
});

export const updateProp = (array: Array<Object>, keyMap: Object) => R.map(renameKeys(keyMap), array);
