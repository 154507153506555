// @flow

import * as R from 'ramda';
import { layout } from '@accordo-feed/micro-frontends-utils';

import * as storage from 'src/utils/storage';
import { LOCAL_STORAGE_KEYS } from 'src/constants';
import { apiKeyToWidgetName } from './overview.constant';
import type { GridItem } from './overview.duck';

/**
 * @see Demo at https://bitbucket.org/accordogroup/consumption.frontend/pull-requests/89/obs-243-moving-widgets-around-on-the
 */
export const onDrag = (
  layout: GridItem[],
  oldItem: GridItem,
  newItem: GridItem,
  placeholder: GridItem,
  e: MouseEvent,
  element: HTMLElement
) => {
  const grid = document.getElementsByClassName('react-grid-layout')[0];
  const translateXMaxValue = grid.offsetWidth - element.offsetWidth;
  const translateYMaxValue = grid.offsetHeight - element.offsetHeight;

  const translateValues = window.getComputedStyle(element).transform.split(',');
  let translateX = parseInt(translateValues[translateValues.length - 2]);
  let translateY = parseInt(translateValues[translateValues.length - 1].slice(0, -1));

  if (translateX > translateXMaxValue) {
    translateX = translateXMaxValue;
  }
  if (translateX < 10) {
    translateX = 10;
  }
  if (translateY > translateYMaxValue - 10) {
    translateY = translateYMaxValue - 10;
  }
  if (translateY < 10) {
    translateY = 10;
  }

  element.style.transform = `translate(${translateX}px, ${translateY}px)`;
};

// This prevents text on the screen be affected by imaginary selection when moving widgets around
// @see Demo at https://bitbucket.org/accordogroup/consumption.frontend/pull-requests/89/obs-243-moving-widgets-around-on-the
// $FlowIgnore
const { classList } = document.body;

export const onDragStart = () => classList.add('disable-user-select');
export const onDragStop = () => classList.remove('disable-user-select');

/**
 * Callback to be called when moving widgets around
 */
export const onLayoutChange = (setWidgetLayout: (GridItem[]) => void): ((layout: GridItem[]) => void) =>
  R.pipe(R.tap(setWidgetLayout), JSON.stringify, storage.saveAs(LOCAL_STORAGE_KEYS.OPTIMIZER_WIDGETS_LAYOUT));

/**
 * Validate widgets layout data structure
 */
export const isLayoutValid: (GridItem[]) => boolean = R.allPass([
  R.is(Array),
  R.complement(R.isEmpty),
  R.all(
    R.allPass([
      R.is(Object),
      // TODO: https://github.com/flow-typed/flow-typed/issues/3217
      // $FlowIgnore
      R.propIs(String, 'i'),
      // $FlowIgnore
      R.propIs(Number, 'x'),
      // $FlowIgnore
      R.propIs(Number, 'y'),
      // $FlowIgnore
      R.propIs(Number, 'w'),
      // $FlowIgnore
      R.propIs(Number, 'h')
    ])
  )
]);

export const defaultWidgetsSetting = [
  { i: apiKeyToWidgetName.plans, w: 2, active: true },
  { i: apiKeyToWidgetName.highCostUsers, w: 1, active: true },
  { i: apiKeyToWidgetName.recommendations, w: 1, active: true },
  { i: apiKeyToWidgetName.departments, w: 1, active: true },
  { i: apiKeyToWidgetName.oneDriveUsers, w: 1, active: true },
  { i: apiKeyToWidgetName.oneDriveUsage, w: 2, active: true },
  { i: apiKeyToWidgetName.users, w: 3, active: true }
];

/**
 * Set users related widgets api data to null for large customers
 */
const removeUsersWidgetsForLargeCustomer = (data: Object): Object => {
  const { isLargeCustomer } = data;
  const transformations = {
    users: R.always(null),
    highCostUsers: R.always(null),
    oneDriveUsers: R.always(null)
  };
  return isLargeCustomer ? R.evolve(transformations, data) : data;
};

/**
 * Process the dashboard api data and
 * clean up the widget setting by
 * 1) add oneDriveUsers, oneDriveUsage
 * 2) set user related widget to null if large customer
 * 3) remove orgId, currency, planHighlights, oneDrive, isLargeCustomer
 */
export const cleanUpDashboardApiData: Object => Object = R.pipe(
  R.converge(R.assoc('oneDriveUsers'), [R.path(['oneDrive', 'topUsers']), R.identity]),
  R.converge(R.assoc('oneDriveUsage'), [R.path(['oneDrive', 'usage']), R.identity]),
  removeUsersWidgetsForLargeCustomer,
  R.omit(['orgId', 'currency', 'planHighlights', 'oneDrive', 'isLargeCustomer'])
);

/**
 * Find the removable widgets
 * based on dashboard api data
 */
const detectRemovableWidgets: Object => Array<string> = R.pipe(
  R.pickBy(R.equals(null)),
  R.keys,
  R.map(key => apiKeyToWidgetName[key])
);

/**
 * Prepare the widget setting after api call
 * before generating the layout setting
 */
const prepareLayoutSetting: (Array<string>) => Array<Object> = removeWidgets =>
  R.map(
    R.when(R.propSatisfies(R.flip(R.includes)(removeWidgets), 'i'), R.assoc('active', false)),
    defaultWidgetsSetting
  );

/**
 * Create layout options based on dashboard api data structure
 */
export const createOverallWidgetSetting: Object => Array<Object> = R.pipe(
  cleanUpDashboardApiData,
  detectRemovableWidgets,
  prepareLayoutSetting
);

/**
 * Set the layout setting based on
 * the dashboard api data structure
 */

export const initWidgetLayout = (setWidgetLayout: Function, widgetsSetting: Array<Object> = defaultWidgetsSetting) =>
  setWidgetLayout(layout.generateConfigForReactGridLayout(widgetsSetting));
