import queryString from 'query-string';

export const decodeUrl = (urlObj: Object = window.location) => {
  let { search, hash } = urlObj;

  if (!search) {
    search = hash ? hash.substring(hash.indexOf('?')) : '';
  }

  return queryString.parse(search.slice(1));
};
