// @flow

import * as R from 'ramda';
import { takeLatest, put, all, fork, select } from 'redux-saga/effects';

import * as actions from './overview.duck';
import * as apiCalls from './overview.api';
import * as searchActions from 'src/components/search/searchBar/searchBar.duck';
import * as selectors from 'src/redux/selectors';
import { DEPT_SPEND_LIST } from 'src/constants';
import { initWidgetLayout, createOverallWidgetSetting } from './overview.utils';

function* getWidgetsDataSaga(action: Object): any {
  const orgId = yield select(selectors.orgIdSelector);
  const isLargeCustomer = yield select(selectors.isLargeCustomerSelector);
  const { setWidgetLayout } = action.payload;

  try {
    const data = yield apiCalls.fetchWidgetsData(orgId);
    const searchData = {
      [DEPT_SPEND_LIST]: R.propOr([], ['departments'], data)
    };

    yield put(searchActions.getInitialDataSuccess(searchData));
    yield put(actions.getWidgetsDataSuccess({ widgets: data, dataLoaded: true }));

    const widgetData = yield select(selectors.widgetsSelector);
    const layoutOption = createOverallWidgetSetting({ ...widgetData, isLargeCustomer });

    initWidgetLayout(setWidgetLayout, layoutOption);
  } catch (err) {}
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getWidgetsData, getWidgetsDataSaga)]);
}
