// @flow

/**
 * Saves an item to localStorage
 */
export const saveAs = (key: string) => (value: string) => localStorage.setItem(key, value);

/**
 * Loads an item from localStorage
 */
export const load = (key: string) => () => localStorage.getItem(key);
