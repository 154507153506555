// @flow

import { core } from '@accordo-feed/micro-frontends-utils';

import { API_KEYS } from 'src/constants';
import { getApiAddress, requestHandler } from 'src/utils';

const overviewWidgetsApi = getApiAddress(API_KEYS.OVERVIEW_WIDGETS_API);

export const fetchWidgetsData = (orgId: string) =>
  requestHandler({
    url: core.replaceAll(overviewWidgetsApi, { orgId })
  });
