import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector('msAuth');

export const msAuthStateSelector = createSelector(moduleSelector, R.prop('msAuthState'));

export const msAuthLoadingSelector = createSelector(moduleSelector, R.prop('msIsLoading'));

export const msAuthLastUpdated = createSelector(moduleSelector, R.prop('msLastProcessed'));

export const msAuthIsViewsLoadedSelector = createSelector(moduleSelector, R.prop('isLoaded'));

export const totalUsersSelector = createSelector(moduleSelector, R.prop('totalUsers'));

export const isLargeCustomerSelector = createSelector(moduleSelector, R.prop('isLargeCustomer'));
