import { fork, all } from 'redux-saga/effects';

import organizationSaga from 'src/redux/modules/organization/organization.saga';
import landingSaga from 'src/pages/landing/landing.saga';
import modernizationPDFSaga from 'src/pages/overview/widgets/softwareAssets/softwareAssets.saga';
import msAuthSaga from 'src/redux/modules/msAuth/msAuth.saga';
import oneDriveDailyStorageSaga from 'src/pages/oneDriveDailyStorage/oneDriveDailyStorage.saga';
import overviewSaga from 'src/pages/overview/overview.saga';
import recommendationsSaga from 'src/pages/recommendations/recommendations.saga';
import userDataSaga from 'src/redux/modules/userData/userData.saga';
import usersSaga from 'src/pages/users/users.saga';
import reportSaga from 'src/redux/modules/report/report.saga';

const effects = [
  fork(organizationSaga),
  fork(landingSaga),
  fork(modernizationPDFSaga),
  fork(msAuthSaga),
  fork(oneDriveDailyStorageSaga),
  fork(overviewSaga),
  fork(recommendationsSaga),
  fork(userDataSaga),
  fork(usersSaga),
  fork(reportSaga)
];

export function* rootSaga() {
  yield all(effects);
}

export default effects;
