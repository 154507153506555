import * as R from 'ramda';
import { createSelector } from 'reselect';
import { url } from '@accordo-feed/micro-frontends-utils';

import { SHELL_IDS } from 'src/constants';
import { getModuleSelector } from 'src/redux/utils';
import { shellIdSelector } from './shell';

export const userDataSelector = getModuleSelector('userData');

export const orgIdSelector = createSelector(
  shellIdSelector,
  userDataSelector,
  (id, data) =>
    id === SHELL_IDS.IPIFNY ? R.pathOr('', ['organizations', '0', 'orgId'], data) : url.getAcoOrgIdFromPathname()
);

export const userIdSelector = createSelector(
  userDataSelector,
  userData => userData.userId
);

const userRolesLegacySelector = createSelector(
  userDataSelector,
  roles => roles.roles
);

const userRolesAuthSelector = createSelector(
  userDataSelector,
  roles => roles.authorization.roles
);

export const userRolesSelector = createSelector(
  userRolesLegacySelector,
  userRolesAuthSelector,
  (legacy, auth) => R.uniq([...legacy, ...auth])
);
