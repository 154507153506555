// @flow

import * as R from 'ramda';
import { jwtDecoder } from '@accordo-feed/micro-frontends-utils';
import { takeLatest, put, all, fork, select } from 'redux-saga/effects';

import * as actions from './userData.duck';
import * as selectors from 'src/redux/selectors';
import { USER_ROLES } from 'src/constants';
import { detectRole, decodeUrl } from 'src/utils';

function* getUserDataStateSaga(): any {
  const shellId = yield select(selectors.shellIdSelector);

  let data = jwtDecoder.decodeToken({
    shellId
  });

  const orgId = R.pathOr('', ['organizations', '0', 'orgId'], data);

  yield put(actions.getUserDataSuccess(data));

  // Handle cases when consultant or analyst to view consumption
  // but don't have an orgId in their user token
  // we will parse the url and use assessmentId for orgId
  if (R.isEmpty(orgId)) {
    const roles = yield select(selectors.userRolesSelector);

    if (detectRole(USER_ROLES.CONSULTANT, roles) || detectRole(USER_ROLES.ANALYST, roles)) {
      const orgId = R.pathOr(null, ['assessmentid'], decodeUrl());
      const newOrgDetails = { organizations: [{ orgId }] };
      data = R.mergeDeepRight(data, newOrgDetails);

      yield put(actions.getUserDataSuccess(data));
    }
  }
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getUserData, getUserDataStateSaga)]);
}
