// @flow

import { takeLatest, put, all, fork, select } from 'redux-saga/effects';

import * as actions from './recommendations.duck';
import * as apiCalls from './recommendations.api';
import * as selectors from 'src/redux/selectors';

function* getRecommendationsSaga(): any {
  yield put(actions.setLoadingState(true));

  const orgId = yield select(selectors.orgIdSelector);

  try {
    const data = yield apiCalls.getRecommendations(orgId);
    yield put(actions.getRecommendationsSuccess(data));
  } catch (err) {
    yield put(actions.setLoadingState(false));
  }
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getRecommendations, getRecommendationsSaga)]);
}
