// @flow

import { apiCommon } from '@accordo-feed/micro-frontends-utils';

import { API_KEYS, API_PATHS, ASSESSMENT_PROXYS, SUBMODULE_NAME } from 'src/constants';

/*************
 *   TYPES   *
 *************/

declare var __DEPLOYMENT_ENV__: string;

const apiMapping = {
  [API_KEYS.MS_AUTH_SIGNIN_API]: API_PATHS.MS_AUTH_SIGNIN_API,
  [API_KEYS.MS_AUTH_STATE_API]: API_PATHS.MS_AUTH_STATE_API,
  [API_KEYS.ORGNIZATION_API]: API_PATHS.ORGNIZATION_API,
  [API_KEYS.USERS_LIST_API]: API_PATHS.USERS_LIST_API,
  [API_KEYS.OVERVIEW_WIDGETS_API]: API_PATHS.OVERVIEW_WIDGETS_API,
  [API_KEYS.ONEDRIVE_DAILY_REPORT_API]: API_PATHS.ONEDRIVE_DAILY_REPORT_API,
  [API_KEYS.OVERVIEW_WIDGETS_API]: API_PATHS.OVERVIEW_WIDGETS_API,
  [API_KEYS.RECOMMENDATIONS_API]: API_PATHS.RECOMMENDATIONS_API,
  [API_KEYS.USERS_API]: API_PATHS.USERS_API,
  [API_KEYS.MODERNIZATION_REPORT_API]:
    ASSESSMENT_PROXYS[__DEPLOYMENT_ENV__ || 'local'] + API_PATHS.MODERNIZATION_REPORT_API,
  [API_KEYS.REFRESH_API]: API_PATHS.REFRESH_API,
  [API_KEYS.DOWNLOAD_REPORT_API]: API_PATHS.DOWNLOAD_REPORT_API
};

const apiConfig = new apiCommon.ApiConfig({
  apiMapping,
  env: __DEPLOYMENT_ENV__,
  storageName: SUBMODULE_NAME
});

export const getApiAddress = (key: string): string => apiConfig.get(key);

export const requestHandler = apiCommon.requestHandler(SUBMODULE_NAME);
