import * as R from 'ramda';
import { SORTING } from 'src/constants';

/*************
 *   TYPES   *
 *************/

type SortOptions = {
  prop: string,
  direction?: string /** @see {SORTING} in `src/constants` */
};

/***************
 *   HELPERS   *
 ***************/

export const sortBy = (opts: SortOptions) => (obj: Object) => {
  const withDirection = opts.direction && opts.direction === SORTING.DESC ? R.descend : R.ascend;
  return R.sortWith([withDirection(R.prop(opts.prop))])(obj);
};

export const sortByAlph = (...path) => (a, b) => {
  const getValueToSort = R.pipe(R.pathOr('', [...path]), R.toLower);
  // prettier-disable
  return R.cond([
    [R.lt, R.always(1)],
    [R.equals, R.always(0)],
    [R.T, R.always(-1)]
  ])(getValueToSort(a), getValueToSort(b));
  // prettier-enable
};

export const sortByNumber = (...path) => (a, b) => (R.path([...path], a) || 0) - (R.path([...path], b) || 0);

export const sortByDate = (...path) => (a, b) =>
  new Date(R.path([...path], a) || '1900-01-01T12:00:00.000Z') -
  new Date(R.path([...path], b) || '1900-01-01T12:00:00.000Z');
