import { createSelector } from 'reselect';

import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector('shell');

export const shellIdSelector = createSelector(
  moduleSelector,
  module => module.id
);

export const shellLoadingSelector = createSelector(
  shellIdSelector,
  id => id === null
);
