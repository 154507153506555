// @flow

import { core } from '@accordo-feed/micro-frontends-utils';

import { API_KEYS } from 'src/constants';
import { getApiAddress, requestHandler } from 'src/utils';

const msAuthStateApi = getApiAddress(API_KEYS.MS_AUTH_STATE_API);

export const getMsAuthState = (orgId: string): Object =>
  requestHandler({
    url: core.replaceAll(msAuthStateApi, { orgId })
  });
