// @flow
import { core } from '@accordo-feed/micro-frontends-utils';

import { API_KEYS } from 'src/constants';
import { getApiAddress, requestHandler } from 'src/utils';
import { handleError } from './oneDriveDailyStorage.utils';

const oneDriveDailyStorageApi = getApiAddress(API_KEYS.ONEDRIVE_DAILY_REPORT_API);

export const getOneDriveDailyStorage = (orgId: string, date: string) =>
  requestHandler({
    url: core.replaceAll(oneDriveDailyStorageApi, { orgId, date }),
    handleError
  });
