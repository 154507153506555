// @flow

import moment from 'moment';
import { TIME_FORMATS, EMPTY_DATE_PLACEHOLDER } from 'src/constants';

const dateDiffTillNow = timestamp => {
  return moment(timestamp).fromNow();
};

export const stringToDate = (timestamp: string) => {
  return moment(timestamp);
};

export const dateConverter = (format: string) => (timestamp: string): string => {
  if (!timestamp) return EMPTY_DATE_PLACEHOLDER;

  switch (format) {
    case TIME_FORMATS.TILL_NOW:
      return dateDiffTillNow(timestamp);
    case TIME_FORMATS.SHORT:
      return moment(timestamp).format(TIME_FORMATS.SHORT);
    default:
      return '';
  }
};
