// @flow

import { STORAGE_UNITS, BYTE_CONVERT_RATE } from 'src/constants';

/**
 * Converts bytes to human readable string
 */

export const convertBytes = (bytes: number) => {
  const i = ~~(Math.log(bytes) / Math.log(1024));

  return (bytes / BYTE_CONVERT_RATE[STORAGE_UNITS[i]]).toFixed() + STORAGE_UNITS[i];
};

export const getBytesUnitByMinRange = (bytes: number, minTriggerNumber: number = 10, fraction: number = 4) => {
  const i = ~~(Math.log(bytes) / Math.log(1024));
  const val = (bytes / BYTE_CONVERT_RATE[STORAGE_UNITS[i]]).toFixed(fraction);

  if (i === 0) return STORAGE_UNITS[i];

  return parseFloat(val) > minTriggerNumber ? STORAGE_UNITS[i] : STORAGE_UNITS[i - 1];
};

export const convertBytesByUnit = (bytes: number, unit: string, fraction: number = 4) =>
  (bytes / BYTE_CONVERT_RATE[unit.toUpperCase()]).toFixed(fraction);
