import { createSelector } from 'reselect';

import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector('recommendations');

export const recommendationsLoadingSelector = createSelector(
  moduleSelector,
  module => module.isLoading
);

export const recommendationsLoadedSelector = createSelector(
  moduleSelector,
  module => module.isLoaded
);

export const recommendationsDataSelector = createSelector(
  moduleSelector,
  module => module.data
);
