import { createSelector } from 'reselect';

import { getModuleSelector } from 'src/redux/utils';

const reportSelector = getModuleSelector('report');

export const reportIsLoadingSelector = createSelector(
  reportSelector,
  module => module.isLoading
);
