// @flow

import { takeLatest, put, all, fork, select } from 'redux-saga/effects';

import * as actions from './msAuth.duck';
import * as apiCalls from './msAuth.api';
import * as selectors from 'src/redux/selectors';

function* getMsAuthStateSaga(): any {
  const orgId = yield select(selectors.orgIdSelector);

  try {
    const { authState, lastProcessed, totalUsers, isLargeCustomer } = yield apiCalls.getMsAuthState(orgId);

    yield put(
      actions.getMsAuthStateSuccess({
        msAuthState: authState,
        msLastProcessed: lastProcessed || '',
        msIsLoading: false,
        isLoaded: true,
        totalUsers,
        isLargeCustomer
      })
    );
  } catch (err) {}
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getMsAuthState, getMsAuthStateSaga)]);
}
