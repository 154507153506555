// @flow

import { takeLatest, put, all, fork, select } from 'redux-saga/effects';
import * as R from 'ramda';

import * as apiCalls from './oneDriveDailyStorage.api';
import * as actions from './oneDriveDailyStorage.duck';
import * as searchActions from 'src/components/search/searchBar/searchBar.duck';
import * as selectors from 'src/redux/selectors';
import { COMPANY_ONEDRIVE_STORAGE_LIST } from 'src/constants';

function* getOneDriveDailyStorageSaga(action: Object): any {
  yield put(actions.toggleLoadingState({ isLoading: true, isErrored: false }));
  const orgId = yield select(selectors.orgIdSelector);
  const date = yield action.payload;
  try {
    const data = yield apiCalls.getOneDriveDailyStorage(orgId, date);
    const searchCompanyOneDrive = R.propOr([], 'users', data);
    yield put(actions.getOneDriveDailyStorageSuccess({ ...data, isLoading: false }));
    yield put(searchActions.getInitialDataSuccess({ [COMPANY_ONEDRIVE_STORAGE_LIST]: searchCompanyOneDrive }));
  } catch (err) {
    yield put(actions.toggleErrorState({ isErrored: true, isLoading: false }));
  }
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getOneDriveDailyStorage, getOneDriveDailyStorageSaga)]);
}
