export const apiKeyToWidgetName = {
  adoption: 'softwareAssets',
  plans: 'planOverview',
  highCostUsers: 'highestCostUsers',
  recommendations: 'recommendations',
  oneDriveUsers: 'highestStorageUsers',
  oneDriveUsage: 'companyStorage',
  departments: 'departmentSpend',
  users: 'users'
};
