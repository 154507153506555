// @flow

import { takeLatest, put, all, fork, select } from 'redux-saga/effects';

import { getLocale } from 'src/utils/locale';
import * as actions from './softwareAssets.duck';
import * as apiCalls from './softwareAssets.api';
import * as selectors from 'src/redux/selectors';

function* getModernizationPDFSaga(): any {
  yield put(actions.toggleLoading({ loading: true }));

  const orgId = yield select(selectors.orgIdSelector);
  const lang = yield getLocale();

  try {
    yield apiCalls.getModernizationBlob(orgId, lang);
    yield put(actions.getModernizationPDFSuccess({ loading: false }));
  } catch (err) {
    yield put(actions.toggleLoading({ loading: false }));
  }
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getModernizationPDF, getModernizationPDFSaga)]);
}
