import { DEFAULT_LANGUAGE } from 'src/constants';

// commenting below code since the application has only one locale
// const getBrowserLanguage = () => {
//   const nav = window.navigator;
//   const { languages } = nav;
//   const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];

//   // support for HTML 5.1 "navigator.languages"
//   if (Array.isArray(languages)) {
//     return R.filter(lang => !R.isEmpty(lang), nav.languages)[0];
//   }

//   // support for other well known properties in browsers
//   let lang;
//   for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
//     lang = nav[browserLanguagePropertyKeys[i]];
//     if (!R.isEmpty(lang)) return lang;
//   }
// };

// // The validation will ignore case differences
// const isValidLocale = locale => {
//   const supportedLocales = R.keys(window.languageEntries).map(locale => locale.toLowerCase());
//   return supportedLocales.includes(locale.toLowerCase());
// };

export const getLocale = () => {
  // const locales = [localStorage.getItem(LANGUAGE_CHOICE), getBrowserLanguage()].filter(locale => locale);

  // for (let locale of locales) {
  //   if (isValidLocale(locale)) return locale;
  // }

  return DEFAULT_LANGUAGE;
};
