// @flow

import FileSaver from 'file-saver';
import { core } from '@accordo-feed/micro-frontends-utils';

import { API_KEYS } from 'src/constants';
import { getApiAddress, requestHandler, createDateFileName } from 'src/utils';

const reportAPI = getApiAddress(API_KEYS.DOWNLOAD_REPORT_API);

export const triggerReportAPICall = (orgId: string, type: string): Object =>
  requestHandler({
    method: 'POST',
    url: core.replaceAll(reportAPI, { orgId }),
    data: {
      type
    },
    handleBody: ({ data }) => FileSaver.saveAs(new Blob([data]), createDateFileName(type, 'csv')),
    responseType: 'blob'
  });
