// @flow

import { core } from '@accordo-feed/micro-frontends-utils';

import { API_KEYS } from 'src/constants';
import { getApiAddress, requestHandler } from 'src/utils';

const recommendationsApi = getApiAddress(API_KEYS.RECOMMENDATIONS_API);

export const getRecommendations = (orgId: string) =>
  requestHandler({
    url: core.replaceAll(recommendationsApi, { orgId })
  });
