import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getModuleSelector, getShellModuleSelector } from 'src/redux/utils';
import { DEFAULT_COUNTRY_CODE } from 'src/constants';
import { isEmptyOrNil } from 'src/utils';

const moduleSelector = getModuleSelector('organization');
const shellOrganizationSelector = getShellModuleSelector('organization');

export const organizationLoadingSelector = createSelector(moduleSelector, R.prop('isLoading'));

export const organizationLoadedSelector = createSelector(moduleSelector, R.prop('isLoaded'));

export const organizationDataSelector = createSelector(moduleSelector, R.prop('data'));

const shellOrganizationDataSelector = createSelector(shellOrganizationSelector, R.prop('data'));

// try to get the country code from shell (msp account), if null fall back to 'us'
const pipeProcessCountryCode = R.pipeWith((f, res) => (isEmptyOrNil(res) ? DEFAULT_COUNTRY_CODE : f(res)));
export const shellCountryCodeSelector = createSelector(
  shellOrganizationDataSelector,
  pipeProcessCountryCode([R.prop('countryCode'), R.toLower()])
);
