// @flow

import { core } from '@accordo-feed/micro-frontends-utils';

import { API_KEYS } from 'src/constants';
import { getApiAddress, requestHandler } from 'src/utils';

const msSigninApi = getApiAddress(API_KEYS.MS_AUTH_SIGNIN_API);

export const fetchSignInUrl = (orgId: string) =>
  requestHandler({
    url: core.replaceAll(msSigninApi, { orgId })
  });
