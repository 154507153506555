// @flow

import { takeLatest, all, fork, select, put } from 'redux-saga/effects';

import * as actions from './report.duck';
import * as apiCalls from './report.api';
import * as selectors from 'src/redux/selectors';

function* refreshAPISaga(action: Object): any {
  const orgId = yield select(selectors.orgIdSelector);
  const type = yield action.payload;

  try {
    yield put(actions.setReportIsLoading(true));
    yield apiCalls.triggerReportAPICall(orgId, type);
    yield put(actions.setReportIsLoading(false));
  } catch (err) {
    yield put(actions.setReportIsLoading(false));
  }
}

export default function*(): any {
  yield all([fork(takeLatest, actions.triggerReportDownload, refreshAPISaga)]);
}
