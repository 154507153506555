// @flow

import * as R from 'ramda';
import { takeLatest, put, all, fork, select } from 'redux-saga/effects';

import * as actions from './users.duck';
import * as apiCalls from './users.api';
import * as searchActions from 'src/components/search/searchBar/searchBar.duck';
import * as selectors from 'src/redux/selectors';
import * as utils from 'src/utils';
import { HOME_LIST, HIGH_COST_LIST, ONEDRIVE_STORAGE_LIST, SORTING } from 'src/constants';

function* getUserListSaga(): any {
  yield put(actions.toggleLoadingState({ isLoading: true }));

  const orgId = yield select(selectors.orgIdSelector);

  try {
    const data = yield apiCalls.fetchUserList(orgId);
    const userHomeSearch = R.propOr([], ['users'], data);
    const highCostSearch = utils.sortBy({ direction: SORTING.DESC, prop: 'cost' })(userHomeSearch);
    const onedriveStorageSearch = utils.sortBy({ direction: SORTING.DESC, prop: 'storageUsed' })(userHomeSearch);

    yield put(actions.getUsersDataSuccess({ users: data.users, isLoading: false, isLoaded: true }));
    yield put(
      searchActions.getInitialDataSuccess({
        [HOME_LIST]: userHomeSearch,
        [HIGH_COST_LIST]: highCostSearch,
        [ONEDRIVE_STORAGE_LIST]: onedriveStorageSearch
      })
    );
  } catch (err) {
    yield put(actions.toggleLoadingState({ isLoading: false }));
  }
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getUsersData, getUserListSaga)]);
}
