// @flow

import FileSaver from 'file-saver';
import { core } from '@accordo-feed/micro-frontends-utils';

import { API_KEYS } from 'src/constants';
import { getApiAddress, requestHandler, createDateFileName } from 'src/utils';

const apiUrl = getApiAddress(API_KEYS.MODERNIZATION_REPORT_API);

export const getModernizationBlob = (orgId: string, lang: string) =>
  requestHandler({
    url: core.replaceAll(apiUrl, { orgId, lang }),
    handleBody: ({ data }) => FileSaver.saveAs(new Blob([data]), createDateFileName('modernization-report', 'pdf')),
    responseType: 'blob'
  });
