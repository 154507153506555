// @flow

import { core } from '@accordo-feed/micro-frontends-utils';

import { API_KEYS } from 'src/constants';
import { getApiAddress, requestHandler } from 'src/utils';

const orgnizationApi = getApiAddress(API_KEYS.ORGNIZATION_API);

export const getOrgData = (orgId: string): Object =>
  requestHandler({
    url: core.replaceAll(orgnizationApi, { orgId })
  });
