import { createSelector } from 'reselect';

import * as utils from 'src/utils';
import { SORTING } from 'src/constants';
import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector('oneDriveDailyStorage');

export const oneDriveDailyDateSelector = createSelector(
  moduleSelector,
  module => module.date
);

export const oneDriveDailyLoadingSelector = createSelector(
  moduleSelector,
  module => module.isLoading
);

export const oneDriveDailyIsLoadedSelector = createSelector(
  moduleSelector,
  module => module.isLoaded
);

const oneDriveDailyUsersSelector = createSelector(
  moduleSelector,
  module => module.users
);

export const oneDriveDailyIsErrorSelector = createSelector(
  moduleSelector,
  module => module.isErrored
);

export const oneDriveDailySortByStorageUsersSelector = createSelector(
  oneDriveDailyUsersSelector,
  users => utils.sortBy({ direction: SORTING.DESC, prop: 'storageUsed' })(users)
);
