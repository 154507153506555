import { createSelector } from 'reselect';

import * as utils from 'src/utils';
import { SORTING } from 'src/constants';
import { getModuleSelector } from 'src/redux/utils';

const usersSelector = getModuleSelector('users');

export const usersPageListSelector = createSelector(
  usersSelector,
  users => users.users
);

export const usersPageLoadingSelector = createSelector(
  usersSelector,
  users => users.isLoading
);

export const usersSortByCostSelector = createSelector(
  usersPageListSelector,
  users => utils.sortBy({ direction: SORTING.DESC, prop: 'cost' })(users)
);

export const usersSortBystorageUsedSelector = createSelector(
  usersPageListSelector,
  users => utils.sortBy({ direction: SORTING.DESC, prop: 'storageUsed' })(users)
);

export const usersIsLoadedSelector = createSelector(
  usersSelector,
  users => users.isLoaded
);
