import * as R from 'ramda';
import { toggleFeature } from '@accordo-feed/micro-frontends-utils';

import { APP_FEATURES } from 'src/constants';
import { getShellModuleSelector } from 'src/redux/utils';

/***************
 *   HELPERS   *
 ***************/

const shellFeatureRulesSelector = getShellModuleSelector('featureRules');
const shellUserDataSelector = getShellModuleSelector('userData');

/**
 * defaultFeatureMapStateToProps
 * @param {Redux State} state
 * @return {Object} feature toggle state contains, sessionDetails & rules
 */
export const defaultFeatureMapStateToProps = state => {
  const rules = shellFeatureRulesSelector(state);
  const userData = shellUserDataSelector(state);
  const { organizations, authorization } = userData;

  return {
    sessionDetails: {
      organizations,
      authorization
    },
    rules
  };
};

const office365HasFeature = R.curry((feature, state) =>
  toggleFeature.hasFeature(feature, state, defaultFeatureMapStateToProps)
);

/*****************
 *   SELECTORS   *
 *****************/

export const hasFeatureCsvExport = office365HasFeature(APP_FEATURES.CSV_EXPORT);

export const hasDemoFeatures = office365HasFeature(APP_FEATURES.DEMO);
