import { createSelector } from 'reselect';

import * as utils from 'src/utils';
import { SORTING } from 'src/constants';
import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector('overview');

export const widgetsSelector = createSelector(
  moduleSelector,
  module => module.widgets
);

export const overviewUsersSelector = createSelector(
  widgetsSelector,
  widgets => widgets.users
);

export const overviewPlansSelector = createSelector(
  widgetsSelector,
  widgets => widgets.plans
);

export const overviewPlansSortByAnnualSpendSelector = createSelector(
  overviewPlansSelector,
  plans => utils.sortBy({ direction: SORTING.DESC, prop: 'annualSpend' })(plans)
);

export const overviewCurrencySelector = createSelector(
  widgetsSelector,
  widgets => widgets.currency
);

export const overviewHighCostUsersSelector = createSelector(
  widgetsSelector,
  widgets => utils.sortBy({ direction: SORTING.DESC, prop: 'cost' })(widgets.highCostUsers)
);

export const overviewOneDriveTopUsersSelector = createSelector(
  widgetsSelector,
  widgets => widgets.oneDrive.topUsers
);

export const overviewOneDriveUsageSelector = createSelector(
  widgetsSelector,
  widgets => widgets.oneDrive.usage
);

export const overviewOneDriveTopUsersSortByUsageSelector = createSelector(
  overviewOneDriveTopUsersSelector,
  users => utils.sortBy({ direction: SORTING.DESC, prop: 'amount' })(users)
);

export const overviewDepartmentSpendSelector = createSelector(
  widgetsSelector,
  widgets => widgets.departments
);

export const departmentSpendSortByCostSelector = createSelector(
  overviewDepartmentSpendSelector,
  departments => utils.sortBy({ direction: SORTING.DESC, prop: 'cost' })(departments)
);

export const overviewRecommendationsSelector = createSelector(
  widgetsSelector,
  widgets => widgets.recommendations
);

export const overviewPageDataLoadedSelector = createSelector(
  moduleSelector,
  module => module.dataLoaded
);

export const overviewPageLayoutSelector = createSelector(
  moduleSelector,
  module => module.layout
);

export const overviewSoftwareAssetsSelector = createSelector(
  widgetsSelector,
  module => module.adoption
);

const softwareAssetsSelector = getModuleSelector('softwareAssets');

export const overviewSoftwareAssetsLoadingSelector = createSelector(
  softwareAssetsSelector,
  module => module.loading
);
