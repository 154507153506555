// @flow

import { currency } from '@accordo-feed/micro-frontends-utils';
import { takeLatest, put, all, fork, select } from 'redux-saga/effects';

import * as actions from './organization.duck';
import * as apiCalls from './organization.api';
import * as currencyActions from 'src/redux/modules/currency/currency.duck';
import * as selectors from 'src/redux/selectors';

function* getOrganizationSaga(): any {
  yield put(actions.setLoadingState(true));

  const orgId = yield select(selectors.orgIdSelector);

  try {
    const data = yield apiCalls.getOrgData(orgId);
    yield put(actions.getOrganizationSuccess(data));

    const countryCode = yield select(selectors.shellCountryCodeSelector);
    const currencyOptions = currency.getCurrencyOptions(countryCode);
    yield put(currencyActions.getCurrencyOptionsSuccess(currencyOptions));
  } catch (err) {
    yield put(actions.setLoadingState(false));
  }
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getOrganization, getOrganizationSaga)]);
}
