// @flow

import * as R from 'ramda';
export const handleError = (err: Object, defaultErrorHandler: Function) => {
  /**
   * Check if error code is not 500, 502 or 400 and let it bubble up to parent shell container, otherwise
   * cut off the event.
   */
  if (!R.anyPass([R.equals(500), R.equals(502), R.equals(400)])(err.response.status)) {
    defaultErrorHandler(err);
  } else {
    throw err;
  }
};
