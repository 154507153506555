import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  HOME_LIST,
  HIGH_COST_LIST,
  DEPT_SPEND_LIST,
  ONEDRIVE_STORAGE_LIST,
  COMPANY_ONEDRIVE_STORAGE_LIST,
  SEARCH_TERM
} from 'src/constants';
import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector('search');

export const userHomeSelector = createSelector(
  moduleSelector,
  module => R.pathOr('', [HOME_LIST], module)
);

export const userHighCostSelector = createSelector(
  moduleSelector,
  module => R.pathOr('', [HIGH_COST_LIST], module)
);

export const deptSpendSelector = createSelector(
  moduleSelector,
  module => R.pathOr('', [DEPT_SPEND_LIST], module)
);

export const userOneDriveSelector = createSelector(
  moduleSelector,
  module => R.pathOr('', [ONEDRIVE_STORAGE_LIST], module)
);

export const companyOneDriveSelector = createSelector(
  moduleSelector,
  module => R.pathOr('', [COMPANY_ONEDRIVE_STORAGE_LIST], module)
);

export const userSearchTermSelector = createSelector(
  moduleSelector,
  module => R.pathOr('', [SEARCH_TERM], module)
);
