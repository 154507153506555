// @flow

import { takeLatest, put, all, fork, select } from 'redux-saga/effects';

import * as actions from './landing.duck';
import * as apiCalls from './landing.api';
import * as selectors from 'src/redux/selectors';

function* getSigninUrlSaga(): any {
  const orgId = yield select(selectors.orgIdSelector);

  try {
    const data = yield apiCalls.fetchSignInUrl(orgId);
    yield put(actions.getSigninUrlSuccess({ signinUrl: data.uri, isLoaded: true }));
  } catch (err) {}
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getSigninUrl, getSigninUrlSaga)]);
}
